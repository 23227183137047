{
  "employees": {
    "title": "Employees",
    "tabs": {
      "personal": {
        "title": "Person",
        "identity": {
          "title": "Identity",
          "bornOnLabel": "Born on",
          "speakingLanguage": {
            "nl": "Dutch speaking",
            "fr": "French speaking",
            "en": "English speaking",
            "gr": "German speaking",
            "other": "Foreign speaking"
          },
          "yearAbrev": "y",
          "monthAbrev": "m"
        }
      },
      "plannedAbsence": {
        "title": "Planned absence",
        "content": {
          "filterAbsences": "Filter absences",
          "noAdd": "It is not possible to add a planned absence for this employee."
        }
      },
      "contract": {
        "title": "Contract",
        "content": {
          "readDateTitle": "Data valid on"
        }
      },
      "payroll": {
        "title": "Payroll",
        "content": {
          "notifications": {
            "numberOfDays": "The number of days are missing for Bicycle allowance (Home-workplace commute). They need to be added before you can update the wage package."
          }
        }
      }
    }
  },
  "customers": {
    "title": "Customers",
    "tabs": {
      "general": {
        "title": "General",
        "content": {
          "profileCard": {
            "title": "Identity",
            "address": "Address",
            "responsible": "Responsible person",
            "enterpriseNumber": "Enterprise number"
          },
          "commercialStatusCard": {
            "title": "Commercial status"
          }
        }
      },
      "environment": {
        "title": "Environments",
        "content": {
          "environmentCard": {
            "title": "Technical info",
            "ip": "IP address",
            "macAddress": "MAC address",
            "baseUrl": "Basis URL",
            "webServer": "Web server",
            "JDKS": "JDKs",
            "os": "OS",
            "hostName": "Host name",
            "tomcatVersion": "Tomcat version",
            "cobolVersion": "COBOL version",
            "cloudLinkVersion": "CloudLink version"
          },
          "javaCard": {
            "title": "Java info",
            "javaVersion": "Version",
            "javaVendor": "Vendor",
            "javaDirectory": "Directory",
            "processors": "Core count",
            "maxMemory": "Max memory",
            "totalMemory": "Total memory"
          },
          "productsCard": {
            "manyPresent": "Products",
            "onePresent": "Product",
            "noPresent": "No products",
            "inactive": "Inactive"
          },
          "userEnvironmentCard": {
            "title": "User environment",
            "name": "Name",
            "homeDirectory": "Home directory",
            "workingDirectory": "Work directory"
          },
          "lastUpdateCard": {
            "title": "Last update"
          },
          "localRunTimeCard": {
            "title": "System info",
            "totalSystemMemory": "Total memory",
            "availableSystemMemory": "Available memory",
            "memorySize": {
              "byte": "Byte",
              "kiloByte": "Kilobyte",
              "megaByte": "Megabyte",
              "gigaByte": "Gigabyte",
              "teraByte": "Terabyte"
            }
          },
          "connectionStatusCard": {
            "title": "Connection status",
            "message": "Message",
            "url": "Url",
            "description": "Description",
            "success": "connected",
            "failure": "disconnected"
          },
          "typeCard": {
            "title": "Environment type",
            "saveBtn": "Save"
          },
          "processedFeedback": {
            "title": "Social and fiscal parameters",
            "tabTitle": {
              "updateOf": "Update of",
              "processingTime": "Processing Time",
              "seconds": "seconds"
            },
            "filesTable": {
              "title": "Changed files",
              "fileName": "File",
              "inserted": "Inserted",
              "updated": "Updated",
              "deleted": "Deleted"
            },
            "errorCard": {
              "present": "There is an error",
              "noPresent": "There are no errors"
            },
            "warningsCard": {
              "manyPresent": "There are warnings",
              "onePresent": "There is a warning",
              "noPresent": "There are no warnings"
            }
          },
          "noEnvironments": "There are no environments for this customer"
        }
      }
    }
  },
  "iam": {
    "title": "IAM",
    "users": {
      "title": "Users"
    },
    "groups": {
      "title": "Groups"
    }
  },
  "common": {
    "empty": "",
    "no": "No",
    "yes": "Yes",
    "noResults": "No results found",
    "save": "Save successful",
    "update": "Update",
    "add": "Add",
    "send": "Send",
    "delete": "Delete",
    "cancel": "Cancel",
    "question": "Question",
    "and": "and",
    "errorWhileSaving": "An error occurred on our servers. Please try to finalise again.",
    "close": "Close",
    "continue": "Continue",
    "at": "at",
    "filter": "Apply filter"
  },
  "countryCode": {
    "150": "Belgium"
  },
  "error": {
    "401": "Unauthorized",
    "403": "Forbidden",
    "404": "Resource Not Found",
    "405": "Method Not Allowed",
    "408": "Request Timeout",
    "415": "UnSupported Media Type",
    "500": "Technical Error"
  },
  "languages": {
    "en": "English",
    "fr": "French",
    "nl": "Dutch"
  },
  "primeng": {
    "monthNames": [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "monthNamesShort": [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ]
  }
}
