<!-- Primary Menu -->
<pux-portal-bar
  (languageChanged)="onLanguageChange($event)"
  [companies]="config()?.companies!"
  [selectedPU]="config()?.selectedPU!"
  [ngClass]="config() ?? 'hidden-config'"
/>
@if (logout()) {

  <div class="buttons-nav-bar">
    <epg-ui-button
    (clickEvent)="onLogoutEvent()"
    color="primary">
     <a class="pi pi-sign-out text-white"></a>
    </epg-ui-button>
  </div>
}

<!-- Secondary Menu -->
<pux-nav-bar>
  <p-menubar [model]="navitems()">
    <ng-template pTemplate="start">
      @if (config()?.applicationName ) {

      <label class="mb-0 mr-5 pux-text-brand font-bold hidden lg:inline">
        {{ config()?.applicationName }}
      </label>

      }
    </ng-template>
  </p-menubar>
</pux-nav-bar>
