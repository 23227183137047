import { Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UxlibPortalbarModule } from '@partena/uxlib-portalbar';
import { UxlibCoreModule } from '@partena/uxlib-core';
import { NavbarUIConfig, NavMenuLinkItem } from './epg-navbar-config';
import { TranslateModule } from '@ngx-translate/core';
import { EpgUiButtonComponent } from '@epg-apps/ui-button';

/**
 * Navbar UI
 * This navbar display a navigation bar and required to have header configuration setup to able to work.
 * This component containts eventEmitter to able to change language.
 *
 * @Input config: NavbarUIConfig - Optional
 *
 * @Input logout: Boolean - Optional
 *
 * @Output selectedLanguageChanged: string
 *
 * @Output onLogout: string
 *
 * @example
 * <epg-navbar-ui (selectedLanguageChanged)="onChangeLanguage($event)" [config]="headerConfig" [logout]="true" (onLogout)="onFunction()"/>
 */
@Component({
  selector: 'epg-navbar-ui',
  standalone: true,
  imports: [CommonModule, UxlibPortalbarModule, UxlibCoreModule, TranslateModule, EpgUiButtonComponent],
  templateUrl: './epg-navbar-ui.component.html',
  styleUrl: './epg-navbar-ui.component.scss',
})
export class EpgNavbarUiComponent {
  navitems = input<NavMenuLinkItem[]>();
  config = input<NavbarUIConfig>();
  logout = input<boolean>(false);
  onLogout = output();

  selectedLanguageChanged = output<string>();

  onLanguageChange(evt: string) {
    this.selectedLanguageChanged.emit(evt);
  }

  onLogoutEvent(){
    this.onLogout.emit();
  }
}
