// The file contents for the current environment will overwrite these during build.
// The build system defperson-info.tsaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// `.env.ts` is generated by the `npm run env` command
export const environment = {
  production: false,
  version: 'acc',
  applicationType: "APPS",
  employeeApi: 'https://acc-epemployee.easypay-group.com/api/',
  cdhApi: 'https://acc-cdh.easypay-group.com/api/',
  iamApi: 'https://acc-apim.easypay-group.com/external/iam/api/',
  defaultTimezone: 'Europe/Brussels',
  baseUrl: "https://acc-apps.easypay-group.com",
  authenticationUrl: "https://acc-portal.easypay-group.com/login",
  logoutUrl: "https://acc-portal.easypay-group.com/logout",
  bypassSecurity: false,
  apimSubscriptionKey: "8619a55459624c58b028beb62fc228cb"
};
