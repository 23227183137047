import { NavMenuLinkItem } from '@epg-apps/epg-ui-navbar';

export const CDHSecondaryMenuConfig: NavMenuLinkItem[] = [
  { label: 'Klanten', routerLink: ['/'], icon: 'fas fa-address-book' },
  { label: 'Werknemers', routerLink: ['/'], icon: 'fas fa-users' },
  {
    label: 'IAM',
    icon: 'fas fa-key',
    items: [
      { label: 'Gebruikers', icon: 'fas fa-user-friends' },
      { label: 'Groepen', icon: 'fas fa-user-cog' },
    ],
  },
];
