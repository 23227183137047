/* eslint-disable @nx/enforce-module-boundaries */
import {inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as commonEnUS from '../../../../../../../node_modules/@partena/uxlib-core/translations/en-US.json';
import { isObject } from '@partena/uxlib-core';


export function mergeDeep(target: any, source: any) {
  const output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          Object.assign(output, {[key]: source[key]});
        } else {
          output[key] = mergeDeep(target[key], source[key]);
        }
      } else {
        Object.assign(output, {[key]: source[key]});
      }
    });
  }
  return output;
}


/**
* This service is used to manage translations.
* 
* Ensure to include this setup in your app and inject th necessary service
* and import TranslateModule from  @ngx-translate 
* ```typescript
* import { TranslateModule } from '@ngx-translate/core';
* ```
* 
* Here is an example usage:
* @example
* ```typescript
* 
* 
* translationService = inject(EpgTranslationsService);
* 
* class AppComponent {
*   constructor() {
*     this.translationService.setTranslation(TranslationLocal.EN, enUS)
*     this.translationService.setDefaultLang(TranslationLocal.EN);
*     this.translationService.use(TranslationLocal.EN);
*   }
* }

* ```
* @example
*  ```typescript
* import { EpgTranslationsService } from '@epg-apps/util-translations';
*
* const epgTranslationsService = new EpgTranslationsService();
*
* epgTranslationsService.setTranslation('en-US', {
*   'hello': 'Hello',
*   'world': 'World'
* });
*
* epgTranslationsService.setDefaultLang('en-US');
*
* epgTranslationsService.use('en-US');
* ```
*/ 

@Injectable({
  providedIn: 'root'
})
export class EpgTranslationsService  {
  translateService = inject(TranslateService)

  setTranslation(locale: string, translationObj: object) {
    const parsedTranslation = JSON.parse(JSON.stringify(translationObj));
    this.translateService.setTranslation(mergeDeep(commonEnUS, parsedTranslation), {})
  }

  setDefaultLang(locale: string) {
    this.translateService.setDefaultLang(locale);
  }

  use(locale: string) {
    this.translateService.use(locale);
  }
}
