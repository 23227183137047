import {Component, computed, inject, Signal} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompleteEnvironment, EnvironmentService} from "@epg-apps/cdh-data-access-environment";
import {AccordionTabOpenEvent} from "primeng/accordion";
import {UxlibCoreModule} from "@partena/uxlib-core";
import {TranslateModule} from "@ngx-translate/core";
import {
  EnvironmentCardOverviewComponent,
  EnvironmentCardUserEnvironmentComponent,
  EnvironmentCardJavaComponent,
  EnvironmentCardLastUpdateComponent,
  EnvironmentCardProductsComponent,
  EnvironmentCardConnectionStatusComponent
} from "@epg-apps/cdh-ui-environment";
import {EnvironmentCardLocalRuntimesComponent} from "@epg-apps/cdh-ui-environment";
import {UiProcessedFeedbackGeneralComponent} from "@epg-apps/cdh-ui-processed-feedback";
import {EnvironmentCardTypeComponent} from "../ui-environment-card-type/environment-card-type.component";
import {AuthenticationService, Role} from "@epg-apps/cdh-authentication-iam";

@Component({
  selector: 'cdh-ui-environment-general',
  standalone: true,
  imports: [CommonModule, UxlibCoreModule, TranslateModule, EnvironmentCardOverviewComponent, EnvironmentCardConnectionStatusComponent, EnvironmentCardUserEnvironmentComponent, EnvironmentCardLocalRuntimesComponent, EnvironmentCardJavaComponent, EnvironmentCardLastUpdateComponent, EnvironmentCardProductsComponent, UiProcessedFeedbackGeneralComponent, EnvironmentCardTypeComponent],
  templateUrl: './environment-general.component.html',
  styleUrl: './environment-general.component.scss',
})
export class EnvironmentGeneralComponent {
  private readonly environmentService = inject(EnvironmentService);

  private environments: Signal<CompleteEnvironment[]> = this.environmentService.completeEnvironments;
  protected sortedEnvironments: Signal<CompleteEnvironment[]> = computed(() => this.environments().sort((env1, env2) => env1.type.sortWeight - env2.type.sortWeight));
  userProfile = inject(AuthenticationService).profileSignal
  canEdit = computed<boolean>(() => {
    return this.userProfile().roles.includes(Role.CDH_ADMIN)
  })

  activeIndex: number | number[] = [];

  forceCloseAccordionTabs(): void {
    this.activeIndex = [];
  }

  loadEnvironmentDetails(event: AccordionTabOpenEvent): void {
    this.environmentService.getCustomerEnvironmentDetailByIndex(event.index)
  }
}
