import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UxlibCoreModule } from '@partena/uxlib-core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EpgTranslationsService } from '@epg-apps/util-translations';
import { AuthenticationService } from '@epg-apps/cdh-authentication-iam';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AuthenticationTokenInterceptor } from '@epg-apps/cdh-authentication';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

const httpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, './i18n/', '.json');

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    importProvidersFrom(TranslateModule.forRoot()),
    importProvidersFrom(UxlibCoreModule.forRoot()),
    importProvidersFrom(BrowserAnimationsModule),

    EpgTranslationsService,
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      })
    ),
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationTokenInterceptor,
      multi: true
    }
  ],
};
