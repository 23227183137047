import {EnvironmentInjector, inject, Injectable, runInInjectionContext, Signal, signal} from "@angular/core";
import {environment} from "@epg-apps/cdh-environments";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {toSignal} from "@angular/core/rxjs-interop";
import {tap} from "rxjs";
import {CompleteEnvironment} from "./completeEnvironment";
import {Environment} from "./environment";
import {DetailedEnvironment} from "./detailedEnvironment";
import {SortableEnum} from "./sortableEnum";
import {UpdateEnvironment} from "./updateEnvironment";

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private static readonly ENVIRONMENT_CDH_API = environment.cdhApi + 'environment';

  http = inject(HttpClient);
  injector = inject(EnvironmentInjector);

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Version: 'v1.0',
    }),
    params: new HttpParams(),
  };

  completeEnvironments = signal<CompleteEnvironment[]>([])
  environmentTypeOptions = signal<SortableEnum[]>([])

  public getCustomerEnvironments(customerId: string): Signal<Environment[]> {
    const options = {...this.httpOptions, params: {customerId}};
    return runInInjectionContext(this.injector, () =>
      toSignal(
        this.http.get<Environment[]>(EnvironmentService.ENVIRONMENT_CDH_API, options)
        .pipe(
          tap(environments => this.completeEnvironments.set(environments.map(environment => ({
            id: environment.id,
            name: environment.name,
            type: this.mapNameToEnvironmentType(environment.type.enumName),
            details: undefined
          }))))
        ),
        {initialValue: []}
      )
    )
  }

  public getCustomerEnvironmentDetailByIndex(index: number): Signal<DetailedEnvironment | undefined> {
    if (this.completeEnvironments()[index].details) return signal<DetailedEnvironment | undefined>(this.completeEnvironments()[index].details)
    const url = EnvironmentService.ENVIRONMENT_CDH_API + "/" + this.completeEnvironments()[index].id;
    return runInInjectionContext(this.injector, () =>
      toSignal(
        this.http.get<DetailedEnvironment>(url, this.httpOptions).pipe(
          tap(detailedEnvironment => this.completeEnvironments.update((completeEnvironment) => {
            completeEnvironment[index] = {
              ...completeEnvironment[index],
              details: detailedEnvironment
            }
            return completeEnvironment
          }))
        )
      )
    )
  }

  public updateEnvironment(id: string, update: UpdateEnvironment): Signal<DetailedEnvironment | undefined> {
    const url = EnvironmentService.ENVIRONMENT_CDH_API + "/" + id;
    return runInInjectionContext(this.injector, () =>
      toSignal(
        this.http.patch<DetailedEnvironment>(url, JSON.stringify(update), this.httpOptions).pipe(
          tap(detailedEnvironment => {
            const index = this.completeEnvironments().findIndex(env => env.id === id);
            if (index >= 0) {
              this.completeEnvironments.update((completeEnvironments) => {
                completeEnvironments[index] = {
                  ...completeEnvironments[index],
                  type: this.mapNameToEnvironmentType(detailedEnvironment.softwareInfo.functionalInfo.designation.enumName),
                  details: detailedEnvironment
                }
                return completeEnvironments
              })
            }
          })
        )
      )
    )
  }

  public getEnvironmentTypes(): Signal<SortableEnum[]> {
    const url = EnvironmentService.ENVIRONMENT_CDH_API + "/types";
    return runInInjectionContext(this.injector, () =>
      toSignal(
        this.http.get<SortableEnum[]>(url, this.httpOptions).pipe(
          tap(list => this.environmentTypeOptions.set(list))
        ),
        {initialValue: []}
      )
    )
  }

  private mapNameToEnvironmentType(name: string): SortableEnum {
    return this.environmentTypeOptions().find(e => e.enumName === name) ?? {enumName: name, sortWeight: 0}
  }
}
